<template>
  <v-container fluid>
    <datatable
      :tableHeaders="tableHeaders"
      :tableData="tableData"
      :page.sync="tablePage"
      :pageLimit.sync="tablePageLimit"
      :itemTotal="tableItemTotal"
      :isLoading="tableLoading"
      actionViewRouteLink="AppointmentDetail"
      @options-update="getList(false, $event)"
      enableSort
    >
      <template #item.appointment_status="{ item }">{{ item.appointment_status | formatAppointmentStatus }} </template>
    </datatable>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import Datatable from '@/components/Datatable.vue'

export default {
  name: 'Appointment',
  components: {
    Datatable,
  },
  data: () => ({
    tableLoading: false,
    tablePage: 1,
    tablePageLimit: 10,
    tableData: [],
    tableItemTotal: 0,

    // --- static data
    tableHeaders: [
      { text: '申請日期', value: 'create_date', sortable: false },
      { text: '姓名', value: 'name', sortable: false },
      { text: '電話', value: 'phone', sortable: false },
      { text: '預約日期', value: 'date', sortable: true },
      { text: '預約時間', value: 'time', sortable: false },
      { text: '狀態', value: 'appointment_status', sortable: false },
      { text: '', value: 'actionView', align: 'end', sortable: false },
    ],

    orderDateSort: null,
  }),
  methods: {
    ...mapActions(['setAlert']),
    getQueries() {
      const query = this.$route.query
      if (this.$validate.regexNumber(query['p']) && parseInt(query['p']) > 0) {
        this.tablePage = parseInt(query['p'])
      }

      if (this.$validate.regexNumber(query['l']) && parseInt(query['l']) > 0) {
        this.tablePageLimit = parseInt(query['l'])
      }
      this.getList(true)
    },

    _getData() {
      return new Promise(async resolve => {
        try {
          let payload = {
            limit: this.tablePageLimit,
            page: this.tablePage - 1,
          }

          if (this.orderDateSort) {
            payload['order_date_sort'] = this.orderDateSort;
          }

          const user = this.getCurrentUserData()
          const { data, total } = await this.$Fetcher.GetAppointments(payload, user.id, user.token)
          this.tableItemTotal = total

          const list = data.map(el => {
            return {
              id: el.id,
              create_date: el.create_date,
              name: el.user_data ? el.user_data.name : '',
              phone: el.user_data ? el.user_data.phone : '',
              date: el.appointment_date.substring(0, 10),
              time: el.appointment_date.substring(11, 16),
              appointment_status: el.appointment_status,
            }
          })

          resolve(list)
        } catch (err) {
          this.$common.error(err)
          if (err) {
            this.$store.dispatch('toggleSnack', {
              type: 'error',
              message: err,
            })
          }
          resolve([])
        }
      })
    },

    async getList(pageInit = false, options = {}) {
      if (options.sortBy && options.sortBy.length) {
        const pos = options.sortBy.indexOf('date');
        if (pos > -1) {
          if (options.sortDesc[pos]) {
            this.orderDateSort = 'desc';
          } else {
            this.orderDateSort = 'asc';
          }
        }
      }
      if (!pageInit) {
        const newQuery = {
          p: this.$validate.regexNumber(this.tablePage) && parseInt(this.tablePage) > 1 ? this.tablePage : undefined,
          l:
            this.$validate.regexNumber(this.tablePageLimit) && parseInt(this.tablePageLimit) !== 10
              ? this.tablePageLimit
              : undefined,
        }

        const validNewQuery = this.$common.validNewQuery(this.$route.query, newQuery)
        if (validNewQuery) {
          this.$router.replace({ query: newQuery })
        }
      }

      this.tableLoading = true

      this._getData().then(list => {
        this.tableData = list
        this.tableLoading = false
      })
    },
  },

  async mounted() {
    this.getQueries()
  },
}
</script>
